:root {
    --PrimaryColor: rgb(59 130 246);
    --SecondaryColor: rgb(256 126 59);
    --PrimaryBtnColor: #ffffff;
    --LightColor: #ffffff;
    --DarkColor: #000000;
    --DarkGrayColor: #757575;
    --LghtGrayColor: #b6b6b6;
    --InputBorder: rgb(226 232 240);
    --InputBgColor: #fff;
    --InputPlaceholder: #9a9a9a;
    --FocusColor: rgb(60 99 162);
    --ErrorColor: rgb(239 68 68);
    --white: #ffffff;
    --backgroundWhite: #ffffff;
    --textPrimaryColor: #444444;
    --greenColor: #33AB4F;
    --gray1: #333333;
    --gray2: #444444;
    --gray3: #666666;
    --gray4: #999999;
    --gray5: #f3f3f9;
    --gray6: #d5d5d5;
    --headerShadow: #bdbdbd;   
    --headerNavColor: #CCCCCC; 
    --leftBarBorder: #F0F0F0;
    --leftBarNavBg: #FAFAFA;
    --redColor: #EC5050;
    --contextMenuBg: #ffffff;
    --contextMenuTextColor: #000000;
    --contextMenuShadow: 0px -2px 8px -4px rgba(31, 32, 38, 0.12), 0px 8px 8px -4px rgba(31, 32, 38, 0.08); 
    --delete-btn-color: #FF9A24;
    --delete-btn-hover-color: #fa8b09;
    --selectInputBorderColor: rgb(226 232 240);
    --selectInputBackgroundColor: #F6F6F8;
    --searchBackgroundColor: #f0f0f0;
    --countBg: #ECECEC;
    --heading: #495057;
}
.bg-green-100 {
    background: rgba(210, 244, 238) !important;
}
.bg-orange-100 {
    background: rgb(255 237 213 ) !important;
}
.bg-orange-100 .highlight {
    background: #f94200 !important;
    color: #fff !important;
    padding: 2px 6px;
    border-radius: 5px;
}
.bg-sky-100 {
    background: rgb(224 242 254 ) !important;
}
.bg-sky-100 .highlight {
    background: #0051ff !important;
    color: #fff !important;
    padding: 2px 6px;
    border-radius: 5px;
}
.bg-purple-100 {
    background: rgb(243 232 255 ) !important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: unset;
}

::-webkit-scrollbar-thumb {
    height: 3px;
    background-color: var(--gray4);
    padding: 2px;
    border-radius: 10px;
}

h1 {
    color: var(--DarkColor);
    font-weight: 400;
}

a {
    color: var(--PrimaryColor);
    text-decoration: none;
    font-weight: 400;
}

a:hover, a:focus {
    outline: none;
    text-decoration: none;
}

.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-25 {
    margin-top: 25px;
}
.mr-5 {
    margin-right: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}

.justify-end {
    justify-content: flex-end;
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.overflow-auto {
    overflow: auto;
}

.w-6 {
    width: 6px;
}
.w-8 {
    width: 8px;
}
.w-10 {
    width: 10px;
}
.w-11 {
    width: 11px;
}
.w-14 {
    width: 14px;
}
.w-15 {
    width: 15px;
}
.w-16 {
    width: 16px;
}
.w-18 {
    width: 18px;
}
.w-20 {
    width: 20px;
}
.w-24 {
    width: 24px;
}
.w-30 {
    width: 30px;
}
.w-40 {
    width: 40px;
}

.font-12 {
    font-size: 12px !important;
}
.font-44 {
    font-size: 44px !important;
}

.row {
    display: flex;
}

.container-fuild {
    width: 100%;
  }
  

.d-flex {
    display: flex;
}

select, textarea, input[type=email], input[type=number], input[type=password], input[type=text] {
    width: 100%;
    background: var(--InputBgColor);
    border: solid 1px var(--InputBorder);
    padding: 10px;
    font-size: 14px;
    /* caret-color: var(--LightColor); */
    color: var(--DarkColorr);
    border-radius: 5px;
}

a {
    cursor: pointer;
}

input::placeholder {
    color: var(--InputPlaceholder);
    opacity: 1;
}

select:focus, textarea:focus, input:focus {
    outline: none;
    border: solid 1px var(--FocusColor);
}
/* select:hover, textarea:hover, input:hover {
    background: #ededed !important;;
} */


button:focus {
    outline: none;
}

.btn {
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: box-shadow linear 0.4s;
    -moz-transition: box-shadow linear 0.4s;    
    -ms-transition: box-shadow linear 0.4s;
    transition: box-shadow linear 0.4s;
    padding: 8px 18px;
    border: solid 1px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-primary:disabled, .btn-primary:disabled:hover {
    background-color: var(--gray6) !important;
    border-color: var(--gray4) !important;
    cursor: not-allowed !important;
    color: var(--gray4) !important;
    border-width: 0px;
}
.btn-primary, .btn-primary:focus, .btn-primary:hover, .btn-primary:active {
    background-color: var(--PrimaryColor) !important;
    border-color: var(--FocusColor) !important;
    color: var(--PrimaryBtnColor) !important;
}

.btn-color-01, .btn-color-01:hover {
    background-color: var(--PrimaryColor) !important;
    border-color: var(--PrimaryColor) !important;
    color: var(--PrimaryBtnColor) !important;
}
.btn-color-02, .btn-color-02:hover {
    background-color: #037608 !important;
    border-color: #037608 !important;
    color: var(--PrimaryBtnColor) !important;
}
.btn-color-03, .btn-color-03:hover {
    background-color: #52168c !important;
    border-color: #52168c !important;
    color: var(--PrimaryBtnColor) !important;
}

.download-btn {
    padding: 4px 18px;
}

.form-error label {
    color: var(--ErrorColor);
}

.form-error input {
    border-color: var(--ErrorColor);
}

.form-error input::placeholder {
    color: var(--ErrorColor);
}

.form-error .error {
    font-size: 11px;
    color: var(--ErrorColor);
}

.group-btn {
    display: flex;
    gap: 12px;
  }
  .group-btn .search {
    width: 240px;
    background-color: var(--searchBackgroundColor);
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0px 13px;
    gap: 11px;
  }
  .group-btn .search input {
    width: 100%;
    background: none;
    border: none;
    height: 32px;
  }
  .input-group {
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
  .input-group label {
    font-size: 13px;
    width: 80px;
  }
  .input-group select {
    border: 1px solid var(--InputBorder);
    background-color: var(--selectInputBackgroundColor);
    border-radius: 4px;
    padding: 6.5px;
    font-size: 12px;
  }

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
    width: 100%;
}

/***Breadcrumb Starts***/
.breadcrumb-container {
    margin-bottom: 10px;
}
.breadcrumb {
    list-style: none;
    display: flex;
}
.breadcrumb li {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}
.breadcrumb li + li:before {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmlld0JveD0iMCAwIDEwIDE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTEuNjY2NSAxNC40NjM2TDguMTQ4MzEgNy45ODE4MUM4LjE0ODMxIDcuOTgxODEgMy42MjI1MSAzLjQ1NjAxIDEuNjY2NSAxLjUiIHN0cm9rZT0iIzQ0NDQ0NCIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCjwvc3ZnPg0K');
    display: inline-block;
    width: 8px;
    margin: 0 8px;
    padding: 1px;
}
.breadcrumb li a {
    color: var(--gray4);
    text-decoration: none;
}
.breadcrumb li a:hover {
    text-decoration: underline;
}
.breadcrumb li a.active {
    color: var(--gray1);
}
.breadcrumb li a.active:hover {
    text-decoration: none;
}

/***Breadcrumb End***/

.grid {
    display: grid;
    grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
    grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
    gap: var(--bs-gap, 16px);
    margin-top: 15px;
}


.inner-form {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  
  .inner-form .form-group {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
  
  .inner-form .form-group label {
    font-size: 14px;
  }
  
  .inner-form .form-group button {
    cursor: pointer;
  }

  .inner-form .form-group .form-group-range {
    display: flex;
    gap: 16px;
  }

  .inner-form .form-group .mat-error {
    font-size: 12px;
    margin-top: -25px;
  }
  
  .inner-form .image-uploader {
    display: flex;
    flex-direction: row;
  }
  .inner-form .image-uploader .uploader {
    display: flex;
    flex-direction: column;
  }
  .inner-form .image-uploader .uploader mat-label {
    font-size: 13px;
    color: #0000008a;
  }
  .inner-form .image-uploader img{
    width: 150px;
  }

  .form-option {
    display: flex;
  }
  
  .form-option .remember-pwd,
  .form-option .forgot-pwd {
    width: 100%;
    font-size: 13px;
  } 
  
  .form-option .remember-pwd {
    color: var(--LghtGrayColor);
    display: flex;
    gap: 5px;
    user-select: none;
  }
  
  .form-option .remember-pwd input {
    width: 15px;
  }

  .grid-1 {
    display: grid;
    /* grid-template-columns: repeat(12,minmax(0,1fr)); */
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    /* grid-template-rows: repeat(var(--bs-rows, 1), 1fr); */
    grid-template-columns: repeat(12,minmax(0,1fr));
    gap: var(--bs-gap, 16px);
    /* grid-template-columns: 60px 60px 60px 60px 60px; */
  /* grid-template-rows: 30px 30px; */
  /* grid-auto-flow: column;
  grid-auto-flow: row; */
  }



  /* .item-a {
    grid-column: 1;
    grid-row: 1 / 3;
  } */
  .item-5 {
    grid-row: 1/3;
    grid-column: 5 / span 8;
  }
  .item-chart {
    grid-row: 1/3;
    grid-column: 3 / span 5;
  }
  .item-chart-2 {
    grid-row: 1/3;
    grid-column: span 5;
  }

  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
  .order-6 {
    order: 6;
  }
  .order-7 {
    order: 5;
  }
  .order-8 {
    order: 6;
  }

.live-device {
    width: 16px;
    height: 16px;
    background-color: green;
    border-radius: 20px;
    display: inline-flex;
}
.offline-device {
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 20px;
    display: inline-flex;
}
.live-device span,
.offline-device span {
    visibility: hidden;
}


.mat-table .mat-header-row .mat-header-cell:last-child {
    /* justify-content: flex-end; */
    visibility: hidden;
}
.mat-table .mat-row .mat-cell:last-child {
    justify-content: flex-end;
    /* visibility: hidden; */
}
.mat-table .mat-row:hover .mat-cell:last-child {
    /* visibility: visible; */
}


.table-search {
    justify-content: end;
}

.liv-table .mat-table, .mat-paginator{
    background: transparent;
}

.liv-table .mat-header-row {
  min-height: 35px;
  background: var(--PrimaryColor);
}
.liv-table .mat-header-row .mat-header-cell {
  color: #fff;
  font-weight: bold;
  /* font-size: 0.875rem; */
}

.liv-table .mat-sort-header-arrow {
  color: var(--backgroundWhite);
}

.liv-table .mat-cell-name {
  width: 100%;
}

.table-checkbox {
    width: 60px;
    flex: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.liv-table .mat-row {
    min-height: 52px;
  }

.liv-table .mat-row:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 251);
  border-bottom: 1px solid rgba(121, 135, 156, 0.18);
  box-shadow: rgba(19, 38, 68, 0.08) 0px 12px 16px -4px, rgba(19, 38, 68, 0.03) 0px 4px 6px -2px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #ff7f3d;
}

.liv-table .mat-cell:first-of-type, .liv-table .mat-header-cell:first-of-type, .liv-table .mat-footer-cell:first-of-type {
    padding-left: 24px;
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
.liv-table .mat-header-cell, .liv-table .mat-cell {
    padding: 0 5px;
}

.liv-table .no-data {
    padding: 0 5px;
    text-align: center;
    padding: 30px 10px;
    font-size: 14px;
}

.modal {
    position: relative;
}
.modal .modal-close {
    background-color: var(--SecondaryColor);
    border-radius: 20px;
    color: var(--backgroundWhite);
    z-index: 1;
}
.modal .modal-close:hover {
    border-radius: 20px !important;
}



/* alert */
.alert {
    position: relative;
    padding: 0.55rem 0.55rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 13px;
}
.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

/* assign controller */
.assign-controller {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 100px;
    border: solid 1px #dbdbdb;
  }
  .assign-controller .search-box {
    width: 100%;
    border-bottom: solid 1px #dbdbdb;
  }
  .assign-controller .search-box input {
    border: none;
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }
  .assign-controller .selection {
    display: flex;
    justify-content: space-around;
  }
  .assign-controller .selection .choices {
    background-color: #f9f9f9;
    border-right: solid 1px #dbdbdb;
  }
  .assign-controller .selection .choices,
  .assign-controller .selection .values {
    width: 100%;
  }
  .assign-controller .selection ul {
    padding: 5px;
    height: 250px;
    overflow: auto;
  }
  .assign-controller .selection ul li {
    list-style-type: none;
    font-size: 14px;
    cursor: pointer;
    min-height: 18px;
    padding: 4px 7px;
    position: relative;
  }
  .assign-controller .selection ul li.disabled {
    opacity: .6;
  }
  .assign-controller .selection ul li:hover {
    background-color: var(--PrimaryColor);
    color: var(--LightColor);
  }
  .assign-controller .selection ul li.disabled:hover {
    background: transparent;
    color: #333;
    cursor: default;
  }
  .assign-controller .selection ul li.disabled:hover  .acf-icon {
    display: none;
  }
  .assign-controller .selection ul li:hover .acf-icon {
    display: block;
  }
  .assign-controller .selection .acf-icon {
    position: absolute;
    top: 4px;
    right: 7px;
    display: none;
  }
  .acf-icon.-minus:before {
    content: '\f056';
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: #000000;
    font-size: 15px;
    background-color: #fff;
    border-radius: 10px;
  }
  .acf-icon.-add:before {
    content: '\f055';
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: #000000;
    font-size: 15px;
    background-color: #fff;
    border-radius: 10px;
  }
  