
@media screen and (max-width: 576px) {
    .login-main {
        width: 90% !important;
    }
    .login-main .logo {
        margin-top: 30px;
    }
    .login-main .logo img {
        width: 200px;
    }
    .login-wrapper {
        margin-top: 25px;
    }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
    .login-main {
        width: 500px;
    }
}

@media screen and (max-width:767px) {
    .login-wrapper {
        flex-direction: column;
    }
    .sep {
        display: none;
    }
    .liv-table {
        overflow-x: auto;
        margin-top: 15px;
    }
    .liv-table .mat-table {
        width: 900px;
    }
    .card-header {
        flex-direction: column;
    }
    .card-header h1.card-heading {
        margin-bottom: 15px;
    }
    .table-search {
        justify-content: center;
    }
    .input-group {
        width: auto;
    }
    
    .row.mobile-view {
        flex-direction: column-reverse !important;
    }
    .mobile-view .main {
        width: auto !important;
        margin-bottom: 85px;
        overflow: hidden !important;
        height: auto !important;
    }
    .mobile-view .page-inner {
        margin: 64px 16px 16px;
    }
    .mobile-view .left-bar, .mobile-view .left-panel, .mobile-view .left-nav, .mobile-view .right-panel, .main {
        height: auto !important;
        margin-top: 0px;
    }
    .mobile-view .left-panel-main {
        position: fixed;
        bottom: 0px;
        width: 100%;
        background: #fafafa;
        /* border-top: solid 1px rgb(226,232,240); */
        z-index: 2;
    }
    .mobile-view .left-nav{
        width: 100%;
        padding: 0px;
        overflow: hidden;
    }
    .mobile-view .left-nav ul{
        display: flex;
        justify-content: space-evenly;
    }
    .mobile-view .left-nav ul li a {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        gap: 2px;
    }
    .mobile-view .left-nav ul ol.group-menu li.heading
    {
        display: none;
    }
    .mobile-view .left-nav ul ol.group-menu {
        padding: 5px 0;
    }
    .mobile-view .fold-btn {
        display: none;
    }
}

@media (min-width: 1200px) { 
    .col-lg-1 {
        grid-column: auto/span 1;
    }
    .col-lg-2 {
        grid-column: auto/span 2;
    }
    .col-lg-3 {
        grid-column: auto/span 3;
    }
    .col-lg-4 {
        grid-column: auto/span 4;
    }
    .col-lg-5 {
        grid-column: auto/span 5;
    }
    .col-lg-6 {
        grid-column: auto/span 6;
    }
    .col-lg-7 {
        grid-column: auto/span 7;
    }
    .col-lg-8 {
        grid-column: auto/span 8;
    }
    .col-lg-9 {
        grid-column: auto/span 9;
    }
    .col-lg-10 {
        grid-column: auto/span 10;
    }
    .col-lg-11 {
        grid-column: auto/span 11;
    }
    .col-lg-12 {
        grid-column: auto/span 12;
    }
    .updated-table .liv-table .mat-header-cell:nth-child(2), .updated-table .liv-table .mat-cell:nth-child(2) {
        width: 180px;
        flex: none;
    }

}
@media (min-width: 992px) and (max-width: 1199px) { 
    .col-md-1 {
        grid-column: auto/span 1;
    }
    .col-md-2 {
        grid-column: auto/span 2;
    }
    .col-md-3 {
        grid-column: auto/span 3;
    }
    .col-md-4 {
        grid-column: auto/span 4;
    }
    .col-md-5 {
        grid-column: auto/span 5;
    }
    .col-md-6 {
        grid-column: auto/span 6;
    }
    .col-md-7 {
        grid-column: auto/span 7;
    }
    .col-md-8 {
        grid-column: auto/span 8;
    }
    .col-md-9 {
        grid-column: auto/span 9;
    }
    .col-md-10 {
        grid-column: auto/span 10;
    }
    .col-md-11 {
        grid-column: auto/span 11;
    }
    .col-md-12 {
        grid-column: auto/span 12;
    }
    .updated-table .liv-table .mat-header-cell:nth-child(2), .updated-table .liv-table .mat-cell:nth-child(2) {
        width: 180px;
        flex: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) { 
    .col-sm-1 {
        grid-column: auto/span 1;
    }
    .col-sm-2 {
        grid-column: auto/span 2;
    }
    .col-sm-3 {
        grid-column: auto/span 3;
    }
    .col-sm-4 {
        grid-column: auto/span 4;
    }
    .col-sm-5 {
        grid-column: auto/span 5;
    }
    .col-sm-6 {
        grid-column: auto/span 6;
    }
    .col-sm-7 {
        grid-column: auto/span 7;
    }
    .col-sm-8 {
        grid-column: auto/span 8;
    }
    .col-sm-9 {
        grid-column: auto/span 9;
    }
    .col-sm-10 {
        grid-column: auto/span 10;
    }
    .col-sm-11 {
        grid-column: auto/span 11;
    }
    .col-sm-12 {
        grid-column: auto/span 12;
    }
}
@media (max-width: 767px) { 
    .col-xs-1 {
        grid-column: auto/span 1;
    }
    .col-xs-2 {
        grid-column: auto/span 2;
    }
    .col-xs-3 {
        grid-column: auto/span 3;
    }
    .col-xs-4 {
        grid-column: auto/span 4;
    }
    .col-xs-5 {
        grid-column: auto/span 5;
    }
    .col-xs-6 {
        grid-column: auto/span 6;
    }
    .col-xs-7 {
        grid-column: auto/span 7;
    }
    .col-xs-8 {
        grid-column: auto/span 8;
    }
    .col-xs-9 {
        grid-column: auto/span 9;
    }
    .col-xs-10 {
        grid-column: auto/span 10;
    }
    .col-xs-11 {
        grid-column: auto/span 11;
    }
    .col-xs-12 {
        grid-column: auto/span 12;
    }
    .header .header-nav {
        display: none !important;
    }
}

@media all and (min-width: 768px) {
    .mobile-display, .more-menu {
        display: none !important;
    }
    
}

@media all and (min-width: 991px) {
}

@media screen and (max-width: 1199px) {
    .item-5, .item-chart, .item-chart-2 {
        grid-row: auto;
    }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
}

@media screen and (max-width: 992px) and (min-width: 960px) {

}

@media screen and (max-width: 1024px) and (min-width: 992px) {

}

@media screen and (max-width: 1200px) and (min-width: 1024px) {

}